<template>
  <Inkoop />
</template>

<script>
import Inkoop from "@/components/Inkoop";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Inkoop,
  },
  metaInfo: {
    title: "Postzegels | Inkoop ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content: "Postzegelss.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>
