<template>
 <v-container>
<v-row class="inkoop">
  <v-col>
<h3> Inkoop </h3>

<img src="@/assets/inkoop/inkopers.png" />

<br>
<h3>Wij kopen veel en graag, daarom hebben wij ook interesse in úw postzegels!</h3>

 
<p>Als u van plan bent om uw verzameling of doubletten (deels) te verkopen, dan nodigen wij u van harte uit om contact met ons op te nemen.</p>
 
<h3>Waarom kunt u het beste aan ons verkopen?</h3>

 
<p>Wij kunnen werkelijk alles van de hele wereld gebruiken. Er gaat geen dag voorbij, zonder dat we kleine of grote partijen inkopen.  </p>

 
<p>Heeft u een verzameling of partij te koop? Bel POSTEGELS.COM - PPC en maak een afspraak met één van onze inkopers, die allemaal tientallen jaren ervaring hebben! Voor u de garantie, dat zij uw postzegels op de juiste waarde taxeren. Voor belangrijke objecten bezoeken wij u graag thuis.</p>
 
<p>Tel: +31 (0)70 3625263   Email: admin@postzegels.com </p>

<img src="@/assets/inkoop/zaak.jpg" />


  </v-col>


  <v-col cols="4">
<!--<img src="@/assets/inkoop/nieuwsflash.jpg" /> -->
<img src="@/assets/inkoop/garantie.jpg" />
<!-- <img src="@/assets/inkoop/verzending.jpg" />-->

  </v-col>

</v-row>
 </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.inkoop{
    font-family: Verdana,Arial,Helvetica,sans-serif;
  font-size: 12px;

}

.inkoop h3{
    font-family: Verdana,Arial,Helvetica,sans-serif;
font-weight: bold;
font-size: medium;
}
</style>